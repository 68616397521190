export const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://mentorkart.org/api";
export const DEEPLINK_BASE_URL =
  process.env.REACT_APP_URL || "https://pwa.mentorkart.com";

export const GET_USER = `${BASE_URL}/pwa/user-detail`;
export const USER_REGISTER = `${BASE_URL}/otp-challenge`;
export const VERIFY_OTP = `${BASE_URL}/otp-verify-challenge`;
export const SOCIAL_OTP = `${BASE_URL}/otp-mobile-challenge`;
export const SIGNUP_DETAILS = `${BASE_URL}/signup-details`;
// export const RESEND_OTP = `${BASE_URL}/resend-otp`
// export const USER_LOGIN = `${BASE_URL}/login`
export const LEAD_DATA_URL = `${BASE_URL}/pwa/store-leads`;
export const USER_MEETING_FEEDBACK = `${BASE_URL}/pwa/video-call-feedback`;
export const GET_ALL_APPOINTMENT = `${BASE_URL}/get-all-appointments`;

export const FETCH_MENTOR_AVAILABILITY = `${BASE_URL}/pwa/get-mentor-slots`;
export const BOOK_MENTOR_SLOT = `${BASE_URL}/pwa/appointment-details`;
export const RESCHEDULE_APPOINTMENT = `${BASE_URL}/pwa/reschedule-appointment-booking`;
export const GENERATE_PAYMENT_URL = `${BASE_URL}/pwa/generate-payment-asset`;
export const APPLY_COUPON = `${BASE_URL}/pwa/coupon-discount-check`;
export const RAZORPAY_VERIFY = `${BASE_URL}/razorpay-verify`;
export const GET_USER_PROFILE = `${BASE_URL}/user-profile`;
export const DELETE_STARTUP_CATEGORY = `${BASE_URL}/delete-startup-category`;
export const UPDATE_STARTUP_CATEGORY = `${BASE_URL}/startup-category-update`;
export const ADD_STARTUP_CATEGORY = `${BASE_URL}/add-startup-category`;
export const DELETE_STARTUP = `${BASE_URL}/delete-startup`;
export const UPDATE_STARTUP = `${BASE_URL}/startup-update`;
export const ADD_STARTUP = `${BASE_URL}/add-startup`;
export const UPDATE_SKILLS = `${BASE_URL}/user-skill-update`;
export const UPDATE_JOB_ROLES = `${BASE_URL}/add-other-interest`;
export const DELETE_EXPERIENCE = `${BASE_URL}/delete-user-experience`;
export const UPDATE_EXPERIENCE = `${BASE_URL}/user-experience-update`;
export const ADD_EXPERIENCE = `${BASE_URL}/add-user-experience`;
export const DELETE_ACHIEVEMENT = `${BASE_URL}/delete-user-achievements`;
export const UPDATE_ACHIEVEMENT = `${BASE_URL}/user-achievements-update`;
export const ADD_ACHIEVEMENT = `${BASE_URL}/add-user-achievements`;
export const DELETE_CERTIFICATION = `${BASE_URL}/delete-user-certification`;
export const UPDATE_CERTIFICATION = `${BASE_URL}/user-certification-update`;
export const ADD_CERTIFICATION = `${BASE_URL}/add-user-certification`;
export const ADD_POR = `${BASE_URL}/add-user-por`;
export const UPDATE_POR = `${BASE_URL}/user-por-update`;
export const DELETE_POR = `${BASE_URL}/delete-user-por`;
export const ADD_PROJECT = `${BASE_URL}/add-user-project`;
export const UPDATE_PROJECT = `${BASE_URL}/user-project-update`;
export const DELETE_PROJECT = `${BASE_URL}/delete-user-project`;
export const DELETE_EDUCATION = `${BASE_URL}/delete-user-education`;
export const UPDATE_EDUCATION = `${BASE_URL}/user-education-update`;
export const ADD_EDUCATION = `${BASE_URL}/add-user-education`;
export const UPDATE_INTEREST = `${BASE_URL}/interest`;
export const OTP_CHALLENGE = `${BASE_URL}/otp-challenge`;
export const OTP_VERIFY = `${BASE_URL}/otp-verify-profile-challenge`;
export const FETCH_INTERESTS = `${BASE_URL}/master/interest`;
export const GLOBAL_SEARCH_SEARCHTERM = `${BASE_URL}/get-search-records`;
export const UPDATE_DOMAIN_INDUSTRY = `${BASE_URL}/user-industry-domain-update`;
export const UPDATE_USER_CATEGORY = `${BASE_URL}/user-categories-update`;

export const UPDATE_PROFILE = `${BASE_URL}/pwa/update-user-profile`;
export const UPLOAD_S3 = `${BASE_URL}/upload-file-S3`;
export const ADD_REVIEW_RATING = `${BASE_URL}/pwa/rating-reviews-add`;
export const EDIT_REVIEW_RATING = `${BASE_URL}/pwa/rating-reviews-edit`;
export const FETCH_ALL_APPOINTMENTS = `${BASE_URL}/pwa/get-appointment`;
export const FETCH_MENTORS_URL = `${BASE_URL}/pwa/mentor-list`;
export const FETCH_MENTORS_INDUSTRY = `${BASE_URL}/pwa/industry-list`;
export const FETCH_PACKAGE_TOPICS = `${BASE_URL}/pwa/package-list`;
export const FETCH_COURSE_TOPICS = `${BASE_URL}/pwa/course-list`;
export const FETCH_MENTOR_INFO_URL = (id) =>
  `${BASE_URL}/pwa/mentor-by-id/${id}`;
export const FETCH_MENTOR_PACKAGE = `${BASE_URL}/pwa/get-mentors-package`;
export const FETCH_COURSES_URL = `${BASE_URL}/pwa/courses-list-byCategory`;
export const FETCH_COURSE_INFO_URL = (id) =>
  `${BASE_URL}/pwa/course-by-id/${id}`;
export const FETCH_PACKAGES_URL = `${BASE_URL}/pwa/packages-list`;
export const FETCH_PACKAGE_INFO_URL = (id) =>
  `${BASE_URL}/pwa/package-by-id/${id}`;
export const FETCH_YOUTUBE_VIDEOS_URL = `${BASE_URL}/pwa/youtube-videos-list`;
export const FETCH_YOUTUBE_VIDEO_BY_ID = (id) =>
  `${BASE_URL}/pwa/youtube-video-by-id/${id}`;
export const FETCH_YOUTUBE_VIDEO_FILTERS = `${BASE_URL}/pwa/Youtube-video-list`;
export const FETCH_INDUSTRIES = `${BASE_URL}/master/industry`;
export const FETCH_DOMAIN_BY_INDUSTRY = (id) =>
  `${BASE_URL}/sso-industry-domain/${id}`;
export const UPDATE_CALENDAR = `${BASE_URL}/calendar`;
export const BOOK_PACKAGE_APPOINTMENT = `${BASE_URL}/pwa/package-appointment-booking`;
export const APPOINTMENT_BY_ID = (id) => `${BASE_URL}/pwa/appointment/${id}`;
export const GET_VIDEO_TOKEN = `${BASE_URL}/generate-zoom-token`;
export const GET_CHAT_TOKEN = `${BASE_URL}/chat-access`;
export const SEND_EMAIL_INVITE = `${BASE_URL}/video-call-invitation`;
export const GET_TRENDING_ASSETS = `${BASE_URL}/pwa/app-content-manager-list`;
export const GET_JGP_BATCH_LIST = `${BASE_URL}/jgp-batch-list`;
export const GET_BATCH_BY_ID = (id) => `${BASE_URL}/jgp-batch-list/${id}`;
export const ADD_BATCH_USER = `${BASE_URL}/jgp-batch-user-add`;
export const UPDATE_BATCH_USER = `${BASE_URL}/jgp-batch-user-update`;
export const GET_BATCH_USER = `${BASE_URL}/jgp-batch-user-list`;
export const GET_CITIES = `${BASE_URL}/master/city`;
export const TEST_REGISTER = (id) => `https://test.mentorkart.com/join/${id}`;

export const FETCH_LEARNING_ALL = (id) => `${BASE_URL}/pwa/get-purchase/${id}`;
export const FETCH_ASSET_PURCHASE = `${BASE_URL}/pwa/order-history-by-id`;
export const ADD_TO_WISHLIST = `${BASE_URL}/pwa/wishlist-add`;
export const REMOVE_FROM_WISHLIST = (id) =>
  `${BASE_URL}/pwa/wishlist-remove/${id}`;
export const GET_WISHLIST = `${BASE_URL}/pwa/wishlist-all`;
export const CHECK_IN_WISHLIST = `${BASE_URL}/pwa/check-isAsset-inWishlist`;

export const GET_CURRENT_LOCATION = "https://geolocation-db.com/json/";
export const LEAD_URL = `${process.env.REACT_APP_LEADSQUARE_HOST_URL}LeadManagement.svc/Lead.Capture?accessKey=u$r1c478dd63180cc84bb8d8f4556e6900a&secretKey=${process.env.REACT_APP_LEADSQUARE_SECRET_KEY}`;

export const GET_AICTE_PAYMENT = `${BASE_URL}/generate-payment-link`;
export const RAZORPAY_VERIFY_AICTE = `${BASE_URL}/aicte-razorpay-verification`;
export const VERIFY_NSDC_PAYMENT = `${BASE_URL}/generate-nsdc-payment-link`;
export const APPOINTMENT_STATUS_UPDATE = `${BASE_URL}/appointment-status-update`;
